import React from 'react'
import { Link } from 'gatsby'

const Nav = () => 
    <nav className="site-nav">
        <div className="site-nav-left">
            <a className="site-nav-logo" href="/">Unreal C++</a>
            <ul className="nav" role="menu">
                <li role="menuitem">
                    <Link to="/">
                        Home
                    </Link>
                </li>
                <li role="menuitem">
                    <Link to="/tags">
                        Tags
                    </Link>
                </li>
            </ul>
        </div>
        <div className="site-nav-right">
            <a className="social-logo" href="https://www.youtube.com/c/HarrisonMcGuire" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2499.99 1755.79"><defs></defs><title>youtube-logo</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M1035.38,1753.62c-476.11-8.77-638.72-16.65-738.68-37.25-67.62-13.68-126.39-44.06-169.48-88.12-33.3-33.35-59.79-84.23-80.33-154.83-17.63-58.81-24.49-107.74-34.28-227.27-15-269.76-18.55-490.31,0-736.73C27.92,373.33,35.34,211.78,137,117.53,185,73.42,240.86,45,303.57,33.3,401.53,14.7,818.87,0,1250.92,0c431.08,0,849.35,14.7,947.4,33.3,78.34,14.71,151.81,58.77,194.95,115.59,92.76,145.92,94.39,327.36,103.8,469.29,3.9,67.63,3.9,451.63,0,519.25-14.65,224.3-26.44,303.66-59.74,386-20.59,51.94-38.17,79.36-68.6,109.74a328.55,328.55,0,0,1-174.39,89.14c-412,31-761.88,37.71-1159,31.36Zm630.94-901.3c-229.22-122.5-448.7-236.09-673-353.66v703.43c236.08-128.34,485-245.92,674-350.74l-1,1Z"/></g></g></svg>
            </a>
            <a className="social-logo" href="https://github.com/Harrison1/unrealcpp" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.78 117.79"><defs></defs><title>Github Logo</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M60.39,0A60.39,60.39,0,0,0,41.3,117.69c3,.56,4.12-1.31,4.12-2.91,0-1.44-.05-6.19-.08-11.24C28.54,107.19,25,96.42,25,96.42c-2.75-7-6.71-8.84-6.71-8.84-5.48-3.75.41-3.67.41-3.67,6.07.43,9.26,6.22,9.26,6.22,5.39,9.23,14.13,6.57,17.57,5,.55-3.9,2.11-6.56,3.84-8.07C36,85.55,21.85,80.37,21.85,57.23A23.35,23.35,0,0,1,28.08,41c-.63-1.52-2.7-7.66.58-16,0,0,5.07-1.62,16.61,6.19a57.36,57.36,0,0,1,30.25,0C87,23.42,92.11,25,92.11,25c3.28,8.32,1.22,14.46.59,16a23.34,23.34,0,0,1,6.21,16.21c0,23.2-14.12,28.3-27.57,29.8,2.16,1.87,4.09,5.55,4.09,11.18,0,8.08-.06,14.59-.06,16.57,0,1.61,1.08,3.49,4.14,2.9A60.39,60.39,0,0,0,60.39,0Z"/><path className="cls-2" d="M22.87,86.7c-.13.3-.6.39-1,.19s-.69-.61-.55-.91.61-.39,1-.19.69.61.54.91Z"/><path className="cls-2" d="M25.32,89.43c-.29.27-.85.14-1.24-.28a.92.92,0,0,1-.17-1.25c.3-.27.84-.14,1.24.28s.47,1,.17,1.25Z"/><path className="cls-2" d="M27.7,92.91c-.37.26-1,0-1.35-.52s-.37-1.18,0-1.44,1,0,1.35.51.37,1.19,0,1.45Z"/><path className="cls-2" d="M31,96.27A1.13,1.13,0,0,1,29.41,96c-.53-.49-.68-1.18-.34-1.54s1-.27,1.56.23.68,1.18.33,1.54Z"/><path className="cls-2" d="M35.46,98.22c-.15.47-.82.69-1.51.49s-1.13-.76-1-1.24.82-.7,1.51-.49,1.13.76,1,1.24Z"/><path className="cls-2" d="M40.4,98.58c0,.5-.56.91-1.28.92s-1.3-.38-1.31-.87.56-.92,1.29-.93,1.3.39,1.3.88Z"/><path className="cls-2" d="M45,97.8c.09.49-.41,1-1.12,1.12s-1.35-.17-1.44-.66.42-1,1.12-1.12,1.35.17,1.44.66Z"/></g></g></svg>
            </a>
        </div>
    </nav>

export default Nav
