import React from 'react'
import Nav from './Nav'

const Navbar = () => 
  <header className="site-header outer">
    <div className="inner">
      <Nav />
    </div>
  </header>

export default Navbar
